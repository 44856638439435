











































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import DashboardLayout from '@/components/DashboardLayout.vue';
import DashboardLink from '@/components/DashboardLink.vue';
import AppNotice from '@/components/AppNotice.vue';
import SCHEDULE_APPROVED from '@/graphql/queries/ScheduleApproved.graphql';
import { Schedule } from '@/types/schema';

export default Vue.extend({
  name: 'DriverDashboard',
  components: {
    AppMain,
    DashboardLayout,
    DashboardLink,
    AppNotice,
  },
  data() {
    return {
      schedule: {
        approved: true,
      } as Schedule,
    };
  },
  computed: {
    isApproved(): boolean {
      return this.schedule.approved;
    },
  },
  apollo: {
    schedule: {
      query: SCHEDULE_APPROVED,
    },
  },
});
